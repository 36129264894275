@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../base/typography' as *;

body {
  color: $gray;
  font-family: $ff-s;
  line-height: 1.6;
  background: $white;
  font-size: 1rem;
  overflow-x: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  position: relative;
  left: 0;

  @include mq-min {
    font-size: 1.125rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
p {
  margin-top: 0;
  margin-bottom: 1em;
}

h1 {
  line-height: 1;
  font-size: 3.125rem;
  color: $blue;

  // @include mq-min { // too big
  //   font-size: 4.25rem;
  // }

  @include mq-max {
    font-size: 2.5rem;
  }
}

h2 {
  @include sans-serif($fs: 2.3rem, $lh: 1.4);

  @include mq-min($width: 1000px) {
    font-size: 3rem;
  }
}
