@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../base/typography' as *;

.nav {
  background: $blue;
  position: fixed;
  right: 0;
  width: 235px;
  height: 100%;
  box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);
  transform: translateX(100%);
  transition: transform 300ms cubic-bezier(0.5, 0, 0.5, 1);
  z-index: 9999;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 1em 2em;
  }

  li {
    margin: 1rem 0;
  }

  a {
    @include sans-serif($fs: 2rem, $color: $dark-blue, $tt: none);
    text-decoration: none;
    color: $white;
  }

  a:hover {
    color: $dark-blue;
  }
}

.nav__toggle {
  position: absolute;
  top: 2em;
  left: 0;
  transform: translateX(-100%);
  background: $blue;
  padding: 1em 0.5em;
  border: 0;
  border-radius: 0.25em 0 0 0.25em;
}

.hamburger {
  display: block;
  position: relative;
  width: 1.5em;
  height: 1px;
  background: $white;
  transition: transform 350ms ease-in-out, opacity 200ms linear;

  &::before,
  &::after {
    width: 1.5em;
    height: 1px;
    background: $white;
    transition: transform 350ms ease-in-out, opacity 200ms linear;
    content: '';
    position: absolute;
    left: 0;
  }

  &::before {
    bottom: 6px;
  }

  &::after {
    top: 6px;
  }
}

//  Navigation open styles

.nav--open {
  .nav {
    transform: translateX(0);
  }
  .hamburger {
    transform: rotate(45deg);
  }
  .hamburger::before {
    opacity: 0;
  }
  .hamburger::after {
    transform: rotate(90deg) translate(-6px);
  }
}
