@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../base/typography' as *;

.contact {
  @include container;
  padding: 5em 0;

  form {
    display: flex;
    flex-direction: column;

    @supports (display: grid) {
      @include mq-min {
        display: grid;
        grid-column-gap: 1em;
        grid-template-areas:
          'name      email'
          'message   message'
          '  .       button';

        .name {
          grid-area: name;
        }
        .email {
          grid-area: email;
        }
        .message {
          grid-area: message;
          textarea {
            height: 10em;
          }
        }
        .form__button {
          grid-area: button;
        }
      }
    }
  }

  h1 {
    text-align: center;
  }

  label {
    display: block;
    margin-top: 1.3em;
    color: $dark-gray;
  }

  input,
  textarea {
    box-sizing: border-box;
    display: block;
    padding: 0.75em;
    width: 100%;
    background-color: lighten($light-gray, 5%);
    border: 1px solid $light-gray;
    font-size: 1.2rem;
  }

  input:focus,
  textarea:focus {
    outline: none;
    border-color: $blue;
  }

  textarea {
    resize: none;
  }

  // js
  .alert {
    border: 1px solid $alert;
  }

  .form__button {
    display: inline-block;
    width: auto;
    margin-top: 1.3em;
    margin-left: auto;
    background-color: $blue;
    border: none;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    cursor: pointer;
    @include sans-serif($fs: 2.5rem, $color: $white);

    &:hover {
      background-color: lighten($blue, 5%);
    }
  }

  span {
    color: $alert;
    font-style: italic;
    top: 0.5em;
    visibility: hidden;
  }
}
