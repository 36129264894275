$white: #FFFFFF;
$light-gray: #E8E9EB;
// $gray: #A7A7A7;
$gray: #6f6f6f;
// $dark-gray: #606060;
$dark-gray: #393939;
// $blue: #03AFC2;
$blue: #009d9a;
$dark-blue: #223843;
// $alert : #FF331F;
$alert : #da1e28;