@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../base/typography' as *;

.welcome {
  background-image: url(/app/img/header-bg.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.welcome__header {
  font-size: 1.5rem;
  font-weight: $fw-n;
  color: $dark-blue;
  line-height: 2;
  text-align: center;
    @include mq-min($width: 1200px) {
      font-size: 2vw;
    }

  span {
    @include sans-serif($fs: 5.5rem, $color: $blue);
    display: block;
    margin-right:-0.16em;

    @include mq-min($width: 1200px){
      font-size: 7vw;
    }

    @include mq-max($width: 400px) {
      font-size: 4rem;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 60%;
    height: 1px;
    background: $dark-blue;
  }

  &::before {
    margin: 0 auto 2em;      
  }

  &::after {
    margin: 2.5em auto 0;      
  }
}



